import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, Tooltip, Backdrop } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getReward, claimReward } from '../../store/RewardSlice';
import Main from '../../layouts/Main';
import Container from '../../components/Container';
import Confetti from 'react-confetti';
import './Rewards.css';

interface Reward {
  icon_on: string;
  icon_off: string;
  icon_animated: string;
  text: string;
  status: boolean;
  unlocked: boolean;
  claimed: boolean;
}

const Rewards = (): JSX.Element => {
  const [rewards, setRewards] = useState([]);
  const [confetti, setConfetti] = useState(false);  
  const [activeReward, setActiveReward] = useState<Reward | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const getRewards = async () => {
      const email  = localStorage.getItem('email');
      const response = await dispatch(getReward(email) as any);
      setRewards(response.payload.Rewards);
    };
    getRewards();
  }, []);

  const handleUnlock = (index: number) => {
    const claim = async (reward) => {
      const rewardsUpdated =  await dispatch(claimReward({
        id: reward.reward_id, 
        email: localStorage.getItem('email') }
      ) as any);
      setRewards(rewardsUpdated.payload.Rewards);
    };

    const reward = rewards[index];
    claim(reward);
    setRewards((prevRewards) =>
      prevRewards.map((reward, i) =>
        i === index ? { ...reward, unlocked: true } : reward
      )
    );

    setActiveReward(reward);
    setConfetti(true);
    setTimeout(() => {
      setConfetti(false);
      setActiveReward(null);
    }, 3000);
  };

  return(
    <Main colorInvert={true}>
      <Box bgcolor={'alternate.main'}>
        <Container>
          <Box padding={2}>
            <Typography variant='h4' gutterBottom>
              Recompensas
            </Typography>
            <Grid container spacing={2}>
              {rewards.map((reward, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    padding={2}
                    bgcolor={reward.status ? 'primary.main' : 'grey.300'}
                    borderRadius={1}
                    width='100%'
                    height={0}
                    paddingBottom='100%'
                    position='relative'
                    className={reward.status && !reward.claimed ? 'blink' : ''}
                    onClick={() => reward.status && handleUnlock(index)}
                    sx={{
                      cursor: reward.status ? 'pointer' : 'default',
                      border: '2px solid',
                    }}
                  >
                    <Tooltip title={reward.text}>
                      <Box
                        position='absolute'
                        top='10%'
                        left='10%'
                        height='80%'
                        width='80%'
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                      >
                        {reward.status ? (
                          <img src={reward.icon_on} alt="Icon On" style={{ width: '100%', height: '100%' }} />
                        ) : (
                          <img src={reward.icon_off} alt="Icon Off" style={{ width: '100%', height: '100%' }} />
                        )}
                      </Box>
                    </Tooltip>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
        {confetti && !activeReward.claimed && (
          <Confetti 
            numberOfPieces={200}
            gravity={0.1}
            confettiSource={{ x: 0, y: 0, w: window.innerWidth, h: window.innerHeight }}
            initialVelocityX={{ min: -10, max: 10 }}
            initialVelocityY={{ min: -10, max: 10 }}
          />
        )}
        {activeReward && !activeReward.claimed && (
          <Backdrop open={true} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Box
              className='reward-container fade-in'
            >
              <img src={activeReward.icon_animated} alt="Animated Icon" />
              <Typography variant='h2' color='reward-title'>
                {activeReward.text}
              </Typography>
            </Box>
          </Backdrop>
        )}
      </Box>
    </Main>
  );
};

export default Rewards;