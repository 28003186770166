import React, { useState, useEffect } from 'react';
import Main from '../../layouts/Main';
import Box from '@mui/material/Box';
import Container from '../../components/Container';
import {
  ChartSection,
  DataSection
} from './components';
import { useTheme } from '@mui/material/styles';

const Dashboard = (): JSX.Element => {
  const theme = useTheme();
  const [user, setUser] = useState({});

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  return(
    <Main colorInvert={true}>
      <Box bgcolor={'alternate.main'}>
        <Container
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            '&:last-child': {
              borderBottom: 0,
            }
          }}
        >
          <h3>¡Hola { user['FirstName'] }, este es tu progreso al día de hoy!</h3>
          <DataSection/>
        </Container>
        <Container>
          <ChartSection/>
        </Container>
      </Box>
    </Main>
  );
};

export default Dashboard;