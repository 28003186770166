import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const signIn = createAsyncThunk<any, any>(
  'auth/signIn',
  async (userCredentials) => {
    const request = await axios.post(`${baseUrl}/auth/login`, userCredentials);
    const response = request.data.data;
    localStorage.setItem('email', userCredentials.user_name);
    localStorage.setItem('user', JSON.stringify(response.user));
    return response;
  }
);

export const signUp = createAsyncThunk<any, any>(
  'auth/signUp',
  async (userCredentials) => {
    const request = await axios.post(`${baseUrl}/auth/signup`, userCredentials);
    const response = await request.data.data;
    return response;
  }
);

export const signOut = createAsyncThunk<any, any>(
  'auth/signOut',
  async (userCredentials) => {
    const request = await axios.post(`${baseUrl}/auth/logout`, userCredentials);
    const response = await request.data.data;
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    return response;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    user: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    }).addCase(signIn.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    }).addCase(signIn.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      console.log(action.error.message);
      state.error = action.error.message;
    }).addCase(signUp.pending, (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    }).addCase(signUp.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    }).addCase(signUp.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      console.log(action.error.message);
      state.error = action.error.message;
    }).addCase(signOut.pending, (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    }).addCase(signOut.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    }).addCase(signOut.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      console.log(action.error.message);
      state.error = action.error.message;
    });
  }
});

export default authSlice.reducer;