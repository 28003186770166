import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemButton
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChatIcon from '@mui/icons-material/Chat';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import QuizIcon from '@mui/icons-material/Quiz';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ListAltIcon from '@mui/icons-material/ListAlt';
import NewspaperIcon from '@mui/icons-material/Newspaper';

interface SidebarProps {
  onSelectInstruction: (systemMessage: string, userMessage: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onSelectInstruction }) => {
  const [open, setOpen] = useState(false);
  const instructions = [
    {
      system: '',
      user: 'Iniciemos la entrevista.'
    },
    {
      system: '',
      user: 'Iniciemos un examen de conocimientos.'
    },
    {
      system: '',
      user: 'Resolvamos casos de estudio sobre las normas.'
    },
    {
      system: '',
      user: 'Necesito un recordatorio para estudiar.'
    },
    {
      system: '',
      user: 'Necesito tu guía para una implementación.'
    },
    {
      system: '',
      user: 'Cuéntame alguna novedad sobre las normas.'
    }
  ];

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  return (
    <>
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        <List>
          <ListItemButton onClick={() => onSelectInstruction(instructions[0].system, instructions[0].user)}>
            <ListItemIcon><WorkOutlineIcon /></ListItemIcon>
            <ListItemText primary="Entrevista simulada" />
          </ListItemButton>
          <ListItemButton onClick={() => onSelectInstruction(instructions[1].system, instructions[1].user)}>
            <ListItemIcon><QuizIcon /></ListItemIcon>
            <ListItemText primary="Exámenes" />
          </ListItemButton>
          <ListItemButton onClick={() => onSelectInstruction(instructions[2].system, instructions[2].user)}>
            <ListItemIcon><MenuBookIcon /></ListItemIcon>
            <ListItemText primary="Casos de estudio" />
          </ListItemButton>
          <ListItemButton onClick={() => onSelectInstruction(instructions[3].system, instructions[3].user)}>
            <ListItemIcon><AccessAlarmIcon /></ListItemIcon>
            <ListItemText primary="Recordatorios de estudio" />
          </ListItemButton>
          <ListItemButton onClick={() => onSelectInstruction(instructions[4].system, instructions[4].user)}>
            <ListItemIcon><ListAltIcon /></ListItemIcon>
            <ListItemText primary="Guías paso a paso" />
          </ListItemButton>
          <ListItemButton onClick={() => onSelectInstruction(instructions[5].system, instructions[5].user)}>
            <ListItemIcon><NewspaperIcon /></ListItemIcon>
            <ListItemText primary="Noticias y actualizaciones" />
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );
};

export default Sidebar;