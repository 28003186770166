import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const setThread = createAsyncThunk<any, any>(
  'chat/setThread',
  async (userCredentials) => {
    const request = await axios.post(`${baseUrl}/chats`, userCredentials);
    const response = await request.data.data;
    return response;
  }
);

export const sendMessage = createAsyncThunk<any, any>(
  'chat/sendMessage',
  async (userCredentials) => {
    const request = await axios.post(`${baseUrl}/chats/ask`, userCredentials);    
    const response = await request.data.data;
    axios.patch(`${baseUrl}/users/accumulated/${userCredentials.email}`, response);
    return response.data;
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    loading: false,
    messages: [],
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendMessage.pending, (state) => {
      state.loading = true;
      state.error = null;
    }).addCase(sendMessage.fulfilled, (state, action) => {
      state.loading = false;
      state.messages = action.payload;
      state.error = null;
    }).addCase(sendMessage.rejected, (state, action) => {
      state.loading = false;
      state.messages = [];
      console.log(action.error.message);
      state.error = action.error.message;
    }).addCase(setThread.pending, (state) => {
      state.loading = true;
      state.error = null;
    }).addCase(setThread.fulfilled, (state, action) => {
      state.loading = false;
      state.messages = action.payload;
      state.error = null;
    }).addCase(setThread.rejected, (state, action) => {
      state.loading = false;
      state.messages = [];
      console.log(action.error.message);
      state.error = action.error.message;
    });
  }
});

export default chatSlice.reducer;